@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorWhite);
}

.content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 64px 32px 0 32px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  max-width: 1200px;
  justify-self: center;
  color: var(--colorBlack);

  & > h2 {
    font-size: 26px;

    @media (--viewportMedium) {
      font-size: 40px;
    }
  }

  @media (--viewportMedium) {
    padding: 24px 0 80px 0;
  }
}
.sectionHorizontal {
  flex-direction: row;
  flex-wrap: wrap;
}
.sectionExtended {
  max-width: 1300px;
}

.heroImageWrapper {
  flex: 0 0 64%;

  & > img {
    width: 400px;
    max-width: 100%;
  }

  @media (--viewportMedium) {
    flex: 0 0 40%;
  }
}

.heroContent {
  flex: 0 1 500px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--colorBlack);
  text-align: center;

  & > h1 {
    font-size: 32px;
    line-height: initial;

    @media (--viewportMedium) {
      font-size: 48px;
      font-weight: 800;
    }
  }

  & > p > span {
    display: block;
  }

  @media (--viewportMedium) {
    text-align: initial;
  }
}

@keyframes scrollDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(10px);
    opacity: 0.85;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.heroScroll {
  display: none;

  @media (--viewportMedium) {
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    cursor: pointer;

    & > span {
      font-size: 14px;
      letter-spacing: 2px;
      transform: rotate(90deg);
      width: fit-content;
    }

    & > svg {
      animation: scrollDown 2s infinite;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.highlights {
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding: 24px;
  border-radius: 16px;
  background: var(--colorBlack);
  color: var(--colorWhite);

  & > div {
    flex: 1 1 calc(33% - 60px);
    display: flex;
    flex-direction: column;

    & > p {
      margin: 0;
    }

    & > svg {
      fill: none;
      stroke: var(--colorWhite);
      stroke-width: 2.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  & > div:nth-child(2) {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      height: 2px;
      width: 32%;
      top: -16px;
      left: calc(50% - 32% / 2);
      background-color: var(--colorGrey800);

      @media (--viewportMedium) {
        height: 32%;
        width: 2px;
        top: calc(50% - 32% / 2);
        left: -40px;
      }
    }
    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 32%;
      bottom: -24px;
      right: calc(50% - 32% / 2);
      background-color: var(--colorGrey800);

      @media (--viewportMedium) {
        height: 32%;
        width: 2px;
        top: calc(50% - 32% / 2);
        right: -40px;
      }
    }
  }

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 80px;
    padding: 64px;
  }
}

.process {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 42px;

  & > .step {
    display: flex;

    & > .card {
      flex: 1 1 25%;
      padding: 24px;
      border-radius: 16px;
      background-color: var(--colorWhite);
      box-shadow: 0 2px 4px var(--colorGrey100);
      color: var(--colorBlack);

      & > p {
        margin: 0;
      }

      & > svg {
        fill: none;
        stroke: #ED3325;
        stroke-width: 2.5;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      @media (--viewportMedium) {
        padding: 48px 32px;
      }
    }

    & > svg {
      display: none;

      @media (--viewportMedium) {
        display: flex;
        flex: 2 0 25%;
        align-self: flex-end;
        stroke: #ED3325;
        fill: #ED3325;
        padding-right: 25%;
      }
    }
    &:nth-child(even) > svg {
      order: -1;
      transform: scaleX(-1);
    }

    &:last-of-type {
      & > .card {
        background-color: #ED3325;
        color: var(--colorWhite);

        & > svg {
          stroke: var(--colorWhite);
        }
      }
      & > svg {
        visibility: hidden;
      }
    }
  }

  @media (--viewportMedium) {
    gap: 0px;
  }
}

.callToAction {
  margin-top: 32px;
}

.submitButton {
  composes: buttonPrimary from global;
  background-color: #ED3325;
  width: 200px;
}
