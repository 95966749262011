@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  @apply --thinScollbar;
  overflow-x: none;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.limitedHeight {
  max-height: 120px;
}

.images {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.imageFileContainer {
  border-radius: 4px;
  width: 140px;
  height: 140px;
  cursor: pointer;
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-height: 100%;
  overflow: hidden;
}

.imageFile {
  /* Layout - image will take space defined by aspect ratio wrapper */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fileItem {
  margin: 4px 4px 0 0;
}

.oneFileItemInRow {
  display: block;
}
