@import '../../styles/customMediaQueries.css';

.root {
  width: 16px;
  fill: var(--marketplaceColor);

  @media (--viewportMedium) {
    width: 20px;
  }
}
