.dropzone {
  composes: buttonSecondaryInline from global;

  float: left;
  padding: 8px;
  margin: 17px 0 0 0;
  width: fit-content;
}

.dropzoneActive {
  background-color: var(--colorGrey400);
}

.dropzoneAccept {
  background-color: var(--marketplaceColorLight);
}

.dropzoneReject {
  background-color: var(--colorFail);
}

.dropzoneDisabled {
  display: none;
}

.icon {
  margin-left: auto;
  width: 18px;
  height: 18px;
}
