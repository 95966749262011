@import '../../styles/customMediaQueries.css';

.root {
  /* Add loading color for the div */
  background-color: var(--colorWhite);
  width: 100%;
  position: relative;
}

.overlay,
.backgroundImageWrapper {
  flex: 1 1 50%;

  @media (--viewportLarge) {
    flex: 1 1 65%;
  }
}

.overlay {
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
