.root {
  display: flex;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  background: var(--colorWhite);
  max-width: 300px;
}

.file {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

.iconFile {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.expired {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.iconClose {
  width: 12px;
  height: 12px;
  margin-left: 16px;
  stroke: none;
  fill: var(--colorGrey200);

  &:hover {
    fill: var(--colorGrey400);
  }
}
