@import '../../styles/customMediaQueries.css';

.root {
  position: absolute;
}

.tooltip {
  z-index: 1;
  max-width: 80vw;
  max-height: 40vh;
  overflow: scroll;

  @media (--viewportMedium) {
    max-width: 600px;
    max-height: calc(50vh - var(--topbarHeightDesktop));
  }
}
