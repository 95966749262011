.toggleContainer {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  padding: 0;

  & input {
    display: none;
  }
}

.smallSwitch {
  composes: switch;

  width: 30px;
  min-width: 30px;
  height: 20px;
  min-height: 20px;
}

.bigSwitch {
  composes: switch;

  width: 52px;
  min-width: 52px;
  height: 32px;
  min-height: 32px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--colorGrey300);
  border: 1px solid transparent;
  transition: 0.2s;
}

.smallSlider {
  composes: slider;

  &.round {
    border-radius: 34px;

    &::before {
      border-radius: 50%;
    }
  }

  &::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: var(--colorWhite);
  }
}

.bigSlider {
  composes: slider;

  &.round {
    border-radius: 34px;

    &::before {
      border-radius: 50%;
    }
  }

  &::before {
    position: absolute;
    content: '';
    height: 28px;
    width: 28px;
    left: 1px;
    bottom: 1px;
    background-color: var(--colorWhite);
  }
}

.input {
  &:checked {
    & + .smallSlider {
      background-color: var(--marketplaceColor);

      &::before {
        transform: translateX(10.5px);
      }

      &:hover {
        border-color: var(--marketplaceColor);
      }
    }

    & + .bigSlider {
      background-color: var(--marketplaceColor);

      &::before {
        transform: translateX(20px);
      }

      &:hover {
        border-color: var(--marketplaceColor);
      }
    }
  }

  &:not(:checked) {
    & + .smallSlider {
      &:hover {
        border-color: var(--colorGrey100);
      }
    }

    & + .bigSlider {
      &:hover {
        border-color: var(--colorGrey100);
      }
    }
  }

  &:disabled {
    & + .smallSlider {
      background-color: var(--colorGrey100);

      &::before {
        background-color: var(--colorGrey400);
      }
    }

    & + .bigSlider {
      background-color: var(--colorGrey100);

      &::before {
        background-color: var(--colorGrey400);
      }
    }

    &:hover {
      & + .smallSlider {
        border-color: transparent;
        cursor: default;
      }

      & + .bigSlider {
        border-color: transparent;
        cursor: default;
      }
    }
  }
}

.label {
  margin-left: 16px;
}

.labelNotChecked {
  color: var(--colorGrey700);
}
